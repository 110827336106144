import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../components/Breadcrumbs';
import Link from '../../components/Link';
import Seo from '../../components/Seo';

const ProverbsPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      proverbs1: allProverb(filter: {category: {eq: "pryroda"}}) {
        nodes {
          slug
          title
        }
      }
      proverbs2: allProverb(filter: {category: {eq: "hospodarska-diialnist-liudyny"}}) {
        nodes {
          slug
          title
        }
      }
    }
  `);

  const proverbs1 = data.proverbs1.nodes;
  const proverbs2 = data.proverbs2.nodes;

  return (
    <>
      <Seo
        title="Українські прислів'я та приказки | Перлини української народної творчості"
        description="Прислів'я — мала форма народної поетичної творчості, короткий, ритмізований вислів, що несе узагальнену думку, висновок, іносказання з дидактичним ухилом."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Українські прислів'я та приказки
          </h1>
          <div className="flex-wrap md:flex justify-between">
            <div>
              <p className="typo-body mt-4 max-w-lg">
                Прислів'я — мала форма народної поетичної творчості, короткий, ритмізований вислів, що несе узагальнену думку, висновок, іносказання з дидактичним ухилом.
              </p>
            </div>
            <div>
              <p className="typo-body mt-4 max-w-lg">
                Приказка — жанр фольклорної прози, короткий сталий образний вислів констатуючого характеру, що має одночленну будову, нерідко становить частину прислів'я, але без висновку, і вживається в переносному значенні.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg my-10 md:my-24">
        <h2 className="typo-h2">
          Приcлів'я та приказки про природу
        </h2>
        <ul className="mt-10">
          {proverbs1.map((proverb) => (
            <li className="typo-h4 mt-4 first:mt-0">
              <Link to={proverb.slug}>
                {proverb.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg my-10 md:my-24">
        <h2 className="typo-h2">
          Приcлів'я та приказки про господарську діяльність людини
        </h2>
        <ul className="mt-10">
          {proverbs2.map((proverb) => (
            <li className="typo-h4 mt-4 first:mt-0">
              <Link to={proverb.slug}>
                {proverb.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ProverbsPage;
